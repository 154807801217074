<template>
  <div class="header-wrapper">
    <nav
      class="navbar navbar-expand-lg fixed-top"
      :class="{ bchlheader: isBCHL }"
    >
      <app-link
        class="navbar-brand"
        to="/"
        v-if="
          this.channelSettings.content &&
          this.channelSettings.content['channel-logo']
        "
      >
        <image-component
          :path="channelSettings.content['channel-logo']"
          class="main-logo"
          alt="Main logo"
        />
      </app-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav">
          <template v-for="item in getSubHeaderLinks">
            <li
              v-if="item.children"
              :key="item.name"
              class="nav-item hover-wrapper"
            >
              <ul
                class="nav-link"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{
                  item.name
                }}
              </ul>
              <div class="dropdown-menu-hover-wrapper">
                <div
                  class="dropdown-menu submenu-wrapp"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <template v-for="itemChild in item.children">
                    <app-link
                      class="dropdown-item"
                      :to="itemChild.url"
                      :key="itemChild.name"
                      exact
                    >
                      {{ itemChild.name }}
                    </app-link>
                  </template>
                </div>
              </div>
            </li>
            <li class="nav-item" :key="item.name" v-else>
              <app-link class="nav-link" :to="item.url" :key="item.url" exact>
                {{ item.name }}
              </app-link>
            </li>
          </template>

          <template v-if="isAuth0Enabled">
            <template v-if="authUser">
              <li class="nav-item">
                <app-link class="nav-link" to="/profile">
                  {{ $t('My Account') }}
                </app-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent.stop="logout">
                  {{ $t('Logout') }}
                </a>
              </li>
            </template>
            <template v-else>
              <li>
                <div
                  class="nav-link"
                  style="cursor: pointer"
                  @click="loginWithAuth0"
                >
                  <span>{{ $t('Login') }}</span> &nbsp;
                  <span v-if="auth0Error">{{ auth0Error }}</span>
                </div>
              </li>
            </template>
          </template>
          <template v-else>
            <template v-if="authUser">
              <li class="nav-item">
                <app-link class="nav-link" to="/profile">
                  {{ $t('My Account') }}
                </app-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent.stop="logout">
                  {{ $t('Logout') }}
                </a>
              </li>
            </template>
            <template v-else>
              <li class="nav-item">
                <app-link class="nav-link" to="/register">
                  {{ $t('Register') }}
                </app-link>
              </li>
              <li class="nav-item">
                <app-link class="nav-link" to="/login">
                  {{ $t('Login') }}
                </app-link>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { MENU_POSITION } from '../../const';
import AppLink from '../../components/base-components/AppLink';
import ImageComponent from '../../components/base-components/ImageComponent';
import { createAuth0Client } from '@auth0/auth0-spa-js';

export default {
  name: 'HeaderComponent',
  components: { ImageComponent, AppLink },
  data() {
    return {
      MENU_POSITION: MENU_POSITION,
      apiKey: this.$storage.get('api_key'),
      auth0Error: null,
    };
  },
  methods: {
    ...mapActions(['DO_LOGOUT', 'GET_API_KEY', 'DO_AUTH0_LOGIN']),
    async logout() {
      const auth0_domain = this.channelSettings?.social['auth0-domain'];
      const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
      const auth0_audience =
        this.channelSettings?.social['auth0-client-audience'];
      if (auth0_domain && auth0_client_id && auth0_audience) {
        const auth0 = await createAuth0Client({
          domain: auth0_domain,
          clientId: auth0_client_id,
        });
        auth0.logout();
      }

      this.DO_LOGOUT().then(() => {
        this.$store.commit('RESET_STATE');
        this.$router.go(this.$router.currentRoute);
      });
    },
    redirectAfterLogin() {
      this.$storage.get('last_page')
        ? this.$router.replace({
            path: this.$storage.get('last_page'),
            query: this.$storage.get('last_page_query'),
          })
        : this.$router.push({ name: 'home' });
    },
    async loginWithAuth0() {
      this.loading = true;
      const auth0_domain = this.channelSettings?.social['auth0-domain'];
      const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
      const auth0_audience =
        this.channelSettings?.social['auth0-client-audience'];

      const auth0 = await createAuth0Client({
        domain: auth0_domain,
        clientId: auth0_client_id,
        auth0_audience: auth0_audience,
        showTerms: true,
        mustAcceptTerms: true,
      });

      await auth0.loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin + '?isAuthenticated=1',
        },
      });
      return;
    },
  },
  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code) {
      try {
        this.loading = true;
        const auth0_domain = this.channelSettings?.social['auth0-domain'];
        const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
        const auth0_audience =
          this.channelSettings?.social['auth0-client-audience'];

        const auth0 = await createAuth0Client({
          domain: auth0_domain,
          clientId: auth0_client_id,
          audience: auth0_audience,
        });

        await auth0.handleRedirectCallback();
        const token = await auth0.getTokenSilently({ code: code });

        console.log('token :>> ', token);
        const isAuthenticated = await auth0.isAuthenticated();
        console.log('isAuthenticated :>> ', isAuthenticated);
        if (isAuthenticated) {
          const accessToken = await auth0.getIdTokenClaims({
            authorizationParams: { audience: auth0_audience },
          });
          this.DO_AUTH0_LOGIN({
            api_key: this.apiKey,
            token: accessToken['__raw'],
          })
            // .then(() => {
            //     // this.GET_PROFILE().then( () => {
            //     //     this.redirectAfterLogin();
            //     // })
            //     this.GET_PROFILE();
            // })
            .catch((data) => {
              this.loading = false;
              console.log('Error data :>> ', data);
              this.auth0Error = 'Incorrect auth';
              auth0.logout();
            });
        }
        this.loading = false;
      } catch (error) {
        console.error('Error exchanging authorization code for tokens:', error);
      }
    }
  },
  watch: {
    $route() {
      const element = document.querySelector('#navbarText');
      element.classList.remove('show');
    },
  },
  computed: {
    ...mapState({
      channelMenu: (state) => state.channel.menu,
      authUser: (state) => state.auth.user,
      channelSettings: (state) => state.channel.settings,
      isBCHL: (state) => state.channel.settings.title === 'BCHL' || false,
    }),
    getSubHeaderLinks() {
      return this.channelMenu.filter((item) => {
        if (item.position === 'header') {
          return item;
        }
        if (item.position === 'both') {
          return item;
        }
      });
    },
    isAuth0Enabled() {
      const auth0_domain = this.channelSettings?.social['auth0-domain'];
      const auth0_client_id = this.channelSettings?.social['auth0-client-id'];
      const auth0_audience =
        this.channelSettings?.social['auth0-client-audience'];
      return auth0_domain && auth0_client_id && auth0_audience;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/colors';
@import 'src/assets/css/mixins';

.header-wrapper {
  position: static;
  top: 0;
  left: 0;
  font-family: var(--settings-header-font-family);
}
.navbar {
  padding: 0 2em;
  font-size: 0.8rem;
  letter-spacing: 2px;

  .navbar-toggler {
    color: rgba($text-primary-rgb, 0.5);
  }

  .navbar-toggler-icon {
    width: 1.6rem;
    height: 2px;
    background-color: rgba($text-primary-rgb, 0.5);
    position: relative;
    &:before,
    &:after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      background-color: rgba($text-primary-rgb, 0.5);
      top: -0.5rem;
    }
    &:after {
      top: 0.5rem;
    }
  }
}

.navbar-nav {
  align-items: center;
}

.main-logo {
  height: 70px;
}

.nav-item {
  position: relative;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-menu {
  position: absolute;
  border: none;
  width: 220px;
  left: 0;
  padding: 0;
  border-top: 2px solid $text-primary;
  background-color: var(--settings-header-background);
  box-shadow: 0 0 20px rgba($text-primary-rgb, 0.2);
  overflow: hidden;
}

.dropdown-item {
  font-size: 0.75rem;
  font-weight: 700;
  color: $text-tertiary-dark;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
  letter-spacing: 0;
}

.navbar-collapse {
  justify-content: flex-end;
}

div {
  .navbar {
    background-color: var(--settings-header-background);
    height: 70px;
  }

  .nav-link,
  .dropdown-item {
    color: var(--settings-header-text-color);
    background-color: var(--settings-header-background);
    &:hover {
      background-color: var(--settings-header-background);
      color: var(--settings-header-hover-color);
    }
  }
}
@include media-min(md) {
  .transparent {
    .navbar {
      background-color: transparent;
    }

    .dropdown-menu {
      border-top: 2px solid $error;
      background-color: $text-tertiary-dark;
    }
  }
}

@include media-max(md) {
  div {
    .navbar {
      height: auto;
      min-height: 70px;
    }
  }

  .nav-item,
  .dropdown-item {
    border-bottom: 1px solid rgba($text-tertiary-dark-rgb, 0.3);
    width: 100%;
    text-align: start;
  }
  .nav-item:last-child,
  .dropdown-item:last-child {
    border-bottom: none;
  }
  .dropdown-menu {
    width: 100%;
  }
  div {
    .dropdown-menu {
      position: relative;
      border-top: none;
      box-shadow: none;
    }
  }
}

.hover-wrapper {
  @include media-min(md) {
    .dropdown-menu-hover-wrapper {
      position: absolute;
      z-index: 2;
      top: 0;
      height: 150%;
      width: 100%;
    }
    .submenu-wrapp {
      display: none;
      position: static;
      top: 0;
      margin-top: 60px;
    }
    &:hover {
      .submenu-wrapp {
        display: block;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
div {
  .bchlheader {
    background-color: rgb(252, 197, 89);
    .nav-link {
      color: #023e72;
    }
  }
}
</style>
